<template>
  <div>
    <router-view />
  </div>
</template>

<script>
/*
 * This is the main page for the /site/search route.
 *
 * Here, we will initially load all the common states needed for route searches.
 *
 * - Campaigns
 * - Offerings
 *
 */

import { mapActions, mapGetters, mapState } from 'vuex'
import Toast from '@/components/shared/Toast.vue'

export default {
  name: 'SearchIndex',
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    ...mapState('site', ['selectedSite']),
  },
  async mounted() {
    await this.fetchSiteCampaigns()
  },
  methods: {
    ...mapActions({ loadSearchCampaigns: 'search/loadSearchCampaigns' }),
    async fetchSiteCampaigns() {
      try {
        const { data: campaigns } = await this.$http.get(`/search/site/${this.selectedSite.value}/campaigns`)
        await this.loadSearchCampaigns(campaigns)
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to load campaigns. Please try again later.',
            type: 'error',
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
